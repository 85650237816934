
import Header from './header/header';
import Footer from './footer/footer';
import { Link } from "react-router-dom";

function Main() {  
    return (
      <div className="Container">
        <Header />
        <Link to="/login"><button >로그인페이지 이동</button></Link>
        <Footer />
      </div>
    );
}
  
export default Main;