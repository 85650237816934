import Header from '../header/header';
import Footer from '../footer/footer';

function Login() {
    return (
        <div className="Container">
            <p>로그인 페이지 입니다.</p>
        </div>
    );
}
  
export default Login;