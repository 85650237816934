import { Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './main'
import Member from './member/login'

function App() {  
  return (
    <Routes>
      <Route path="/" element={<Main/>}></Route>
      <Route path="/login" element={<Member/>}></Route>
    </Routes>
  );
}

export default App;
